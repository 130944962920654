(function () {
	// Функция для скролла вверх
	var goTop = function () {
		var gotop = $('.go-top');
		$(window).scroll(function () {
			if ($(this).scrollTop() > 600) {
				gotop.addClass('show');
			} else {
				gotop.removeClass('show');
			}
		});
		gotop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 });
			return false;
		});
	}; // Go Top

	goTop();	
})();

// cлайдер на главной странице 
(function(){
	if($('.js-slider').length) {
		$('.js-slider').slick({
			arrows: false,
			responsive: [
				{
				  breakpoint: 480,
				  settings: {
					adaptiveHeight: true
				  }
				}
			]
		});

		$('.js-slider-btn-prev').click(function(){
			$('.js-slider').slick('slickPrev');
		});

		$('.js-slider-btn-next').click(function(){
			$('.js-slider').slick('slickNext');
		});
	}
})();

// функция валидации формы
(function () {

	if ($('[data-validation]').length) {
		initializeValidate();
	}
	if ($('.form')) {
		clearForm();
	}

	function clearForm() {
		var inputs = $('.form').find('input, textarea'),
			newVal = '';

		for (i = 0; i < inputs.length; i++) {
			inputs.eq(i).val(newVal);
		}
	}

	/* Validate Form */
	function initializeValidate() {
		$('[data-validation]').each(function () {
			var validator = $(this),
				inputs = validator.find('input:not(:checkbox, #order), textarea'),
				submit = validator.find('button[type=submit]'),
				stopSubmit = false,
				urlAction = validator.data('action');


			inputs.each(function () {
				$(this).focus(function () {
					$(this).siblings().addClass('hide')
					$(this).parent().removeClass('invalid')
				});
				$(this).blur(function () {
					if (!($(this).val())) {
						$(this).siblings().removeClass('hide')
					};
				});
			});
			validator.on('change keyup', 'input[data-name]', function () {
				var elm = $(this);
				checkValidity(elm);
			});

			submit.on('click', function (e) {
				var mass = [];

				stopSubmit = true;

				for (var i = 0; i < inputs.length; i++) {

					var input = inputs[i];
					mass.push(input);

					if (input.checkValidity() == true) {
						var elm = input;
						checkValidity(elm);
					}

					if ($(input).parent().hasClass('valid')) {
						stopSubmit = false;
					} else {
						stopSubmit = true;
						break;
					}
				}

				if (stopSubmit) {
					e.preventDefault();
				} else {
					var data = validator.serialize();
					e.preventDefault();
					$.ajax({
						type: "POST",
						url: urlAction,
						data: data
					}).done(function () {
						setTimeout(function () {
							$('[data-remodal-id]').each(function () {
								var modal = $(this).remodal();
								modal.close();
							});
							var successRemodal = $('[data-remodal-id=success]').remodal();

							successRemodal.open();

						}, 400);
					});
					return false
				}
			});
		});
	}

	function checkValidity(elm) {
		var elm = $(elm),
			val = elm.val(),
			block = elm.parent(),
			name_reg = /^[A-Za-zА-Яа-яЁё\-\s]+$/,
			text_reg = /^[A-Za-zА-Яа-яёЁ\s\d]/,
			mail_reg = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i,
			phone_reg = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
			num_reg = /^\d+$/;


		if (elm.prop('disabled')) {
			return;
		} else if (elm.is('[data-name="name"]')) {
			if (name_reg.test(val)) {
				block.removeClass('invalid').addClass('valid');
			} else {
				block.removeClass('valid').addClass('invalid');
			}
		} else if (elm.is('[data-name="email"]')) {
			if (mail_reg.test(val)) {
				block.removeClass('invalid').addClass('valid');
			} else {
				block.removeClass('valid').addClass('invalid');
			}
		} else if (elm.is('[data-name="phone"]')) {
			if (phone_reg.test(val)) {
				block.removeClass('invalid').addClass('valid');
			} else {
				block.removeClass('valid').addClass('invalid');
			}
		} else if (elm.is('[data-name="num"]')) {
			if (num_reg.test(val)) {
				block.removeClass('invalid').addClass('valid');
			} else {
				block.removeClass('valid').addClass('invalid');
			}
		} else if (elm.is('[data-name="text"]')) {
			if (text_reg.test(val)) {
				block.removeClass('invalid').addClass('valid');
			} else {
				block.removeClass('valid').addClass('invalid');
			}
		}
	}
})();

// функция добавления в форму услуги
(function () {
	var btn = $('.js-package-btn'),
		input = $('.order').find('input#order'),
		packages = [
			'Пакет - СТАРТ',
			'Пакет - СТАНДАРТ',
			'Пакет - ПРЕМИУМ'
		];

	btn.click(function () {
		console.log(11);
		
		var thisBtn = $(this),
			index;
		if (thisBtn.closest('.js-package__item')) {
			index = thisBtn.closest('.js-package__item').index();
			input.attr('value', packages[index]);
		}
	});
})();